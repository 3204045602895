import { CDN_URL } from "@env";
import { TGIFTypes } from "./image-types/tgif-types";

const TGIFAssets: TGIFTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/tgif/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/tgif/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/tgif/images/img_2_€.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/tgif/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/tgif/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/tgif/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/tgif/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/tgif/images/img_7.png",
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_37.png",
  "seq_0_38": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_38.png",
  "seq_0_39": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_39.png",
  "seq_0_40": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_40.png",
  "seq_0_41": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_41.png",
  "seq_0_42": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_42.png",
  "seq_0_43": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_43.png",
  "seq_0_44": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_44.png",
  "seq_0_45": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_45.png",
  "seq_0_46": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_46.png",
  "seq_0_47": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_47.png",
  "seq_0_48": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_48.png",
  "seq_0_49": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_49.png",
  "seq_0_50": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_50.png",
  "seq_0_51": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_51.png",
  "seq_0_52": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_52.png",
  "seq_0_53": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_53.png",
  "seq_0_54": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_54.png",
  "seq_0_55": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_55.png",
  "seq_0_56": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_56.png",
  "seq_0_57": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_57.png",
  "seq_0_58": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_58.png",
  "seq_0_59": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_59.png",
  "seq_0_60": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_60.png",
  "seq_0_61": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_61.png",
  "seq_0_62": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_62.png",
  "seq_0_63": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_63.png",
  "seq_0_64": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_64.png",
  "seq_0_65": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_65.png",
  "seq_0_66": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_66.png",
  "seq_0_67": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_67.png",
  "seq_0_68": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_68.png",
  "seq_0_69": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_69.png",
  "seq_0_70": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_70.png",
  "seq_0_71": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_71.png",
  "seq_0_72": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_72.png",
  "seq_0_73": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_73.png",
  "seq_0_74": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_74.png",
  "seq_0_75": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_75.png",
  "seq_0_76": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_76.png",
  "seq_0_77": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_77.png",
  "seq_0_78": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_78.png",
  "seq_0_79": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_79.png",
  "seq_0_80": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_80.png",
  "seq_0_81": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_81.png",
  "seq_0_82": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_82.png",
  "seq_0_83": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_83.png",
  "seq_0_84": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_84.png",
  "seq_0_85": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_85.png",
  "seq_0_86": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_86.png",
  "seq_0_87": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_87.png",
  "seq_0_88": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_88.png",
  "seq_0_89": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_89.png",
  "seq_0_90": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_90.png",
  "seq_0_91": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_91.png",
  "seq_0_92": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_92.png",
  "seq_0_93": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_93.png",
  "seq_0_94": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_94.png",
  "seq_0_95": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_95.png",
  "seq_0_96": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_96.png",
  "seq_0_97": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_97.png",
  "seq_0_98": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_98.png",
  "seq_0_99": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_99.png",
  "seq_0_100": CDN_URL + "/image-assets/animation-assets/tgif/images/seq_0_100.png",
};

export default TGIFAssets;
