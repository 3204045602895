import { CDN_URL } from "@env";
import { BuyshopTypes } from "./image-types/buyshop-types";

const BuyshopAssets: BuyshopTypes = {
  // SHOP
  "shop_img_0": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_0.png",
  "shop_img_1": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_1.png",
  "shop_img_2": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_2.png",
  "shop_img_3": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_3.png",
  "shop_img_4": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_4.png",
  "shop_img_5": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_5.png",
  "shop_img_6": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_6.png",
  "shop_img_7": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_7.png",
  "shop_img_8": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_8.png",
  "shop_img_9": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_9.png",
  "shop_img_10": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_10.png",
  "shop_img_11": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_11.png",
  "shop_img_12": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_12.png",
  "shop_img_13": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_13.png",
  "shop_img_14": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_14.png",
  "shop_img_15": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_15.png",
  "shop_img_16": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_16.png",
  "shop_img_17": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_17.png",
  "shop_img_18": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_18.png",
  "shop_img_19": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_19.png",
  "shop_img_20": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_20.png",
  "shop_img_21": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_21.png",
  "shop_img_22": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_22.png",
  "shop_img_23": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_23.png",
  "shop_img_24": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_24.png",
  "shop_img_25": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_25.png",
  "shop_img_26": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_26.png",
  "shop_img_27": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_27.png",
  "shop_img_28": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_28.png",
  "shop_img_29": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_29.png",
  "shop_img_30": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_30.png",
  "shop_img_31": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_31.png",
  "shop_img_32": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_32.png",
  "shop_img_33": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_33.png",

  // SUBSCRIPTION
  "subscription_img_0": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_0.png",
  "subscription_img_1": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_1.png",
  "subscription_img_2": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_2.png",
  "subscription_img_3": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_3.png",
  "subscription_img_4": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_4.png",
  "subscription_img_5": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_5.png",
  "subscription_img_6": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_6.png",
  "subscription_img_7": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_7.png",
  "subscription_img_8": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_8.png",
  "subscription_img_9": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_9.png",
  "subscription_img_10": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_10.png",
  "subscription_img_11": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_11.png",
  "subscription_img_12": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_12.png",
  "subscription_img_13": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_13.png",
  "subscription_img_14": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_14.png",
};

export default BuyshopAssets;
